// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$md-royal-blue: (
  50: #e0e6ee,
  100: #b3c0d5,
  200: #8096b9,
  300: #4d6c9c,
  400: #264d87,
  500: #002d72,
  600: #00286a,
  700: #00225f,
  800: #001c55,
  900: #001142,
  A100: #778aff,
  A200: #445eff,
  A400: #1132ff,
  A700: #0023f6,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

$md-mustard: (
  50: #fef6e4,
  100: #fce8bb,
  200: #fbd98d,
  300: #f9c95f,
  400: #f7be3d,
  500: #f6b21b,
  600: #f5ab18,
  700: #f3a214,
  800: #f29910,
  900: #ef8a08,
  A100: #ffffff,
  A200: #fff2e5,
  A400: #ffdab2,
  A700: #ffce98,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

$sors-primary: mat.define-palette($md-royal-blue);
$sors-accent: mat.define-palette($md-mustard);

// The warn palette is optional (defaults to red).
$sors-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$sors-theme: mat.define-light-theme($sors-primary, $sors-accent, $sors-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($sors-theme);

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

input{
  min-height: 60px;
}

.mdc-notched-outline {
  color: rgba(0, 0, 0, 0.20);
}

.mat-mdc-raised-button:not(:disabled){
  background-color: #002d72 !important;
  color: #fff !important;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  min-height: 60px !important;
}

.mat-mdc-form-field-hint-end {
  font-size: 10px !important;
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-form-field-error {
  margin-left: -5px;
  color: #f44336;
}
div.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  background-color: white;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  background-color: white;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  font-size: 15px;
}

.mat-mdc-checkbox label {
  height: 33px;
}

.mdc-tab__text-label {
  font-size: 25px;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.50) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  background-color: #002d72;
}

.mdc-checkbox__checkmark-path {
  color: #fff;
}

.mat-mdc-text-field-wrapper:hover {
  outline: auto !important;
}

.mat-form-field-invalid .mat-mdc-text-field-wrapper:hover {
  outline: none !important;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
  color: #000;
  background: #fff;
  padding-left: 5px;
  padding-right: 5px;
}

.mdc-text-field--invalid {
  
  border: 1px solid #f44336;
  border-radius: 5px !important;
}

.mat-mdc-text-field-wrapper--invalid {
  border-radius: 5px;
}
.mat-mdc-select-trigger{
  height: 60px !important;
}
.mat-mdc-text-field-wrapper{
  height: 60px !important;
}
.mdc-evolution-chip-set .mdc-evolution-chip {
  height: 70px !important;
  margin-left: -5px !important;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  height: 25px !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #0000000a !important;
}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
  margin-left: -5px !important;
  padding: 10px !important;
}
.mat-mdc-select-value-text {
  margin-left: 5px !important;
}
.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  background-color: #fff;
}

.mdc-checkbox {
  height: 30px !important;
}
 
mat-tooltip-component div{
  background: #616161 !important;
  color: #fff !important;
  border-radius: 5px;
}


.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label{
  color: #525252 !important;
}

.mdc-text-field--invalid .mat-mdc-floating-label.mdc-floating-label{
  color: #f44336 !important;
}

.mdc-text-field--invalid .mdc-text-field--outlined{
  --mdc-outlined-text-field-outline-width: 0px;
      outline: none;
}

.mat-warn .mat-mdc-button-persistent-rippl{
  background-color: #f44336;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #191616e9 !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: #fff !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #f6b21b !important;
}
